<template>
  <div v-if="settings">
    <v-card>
      <v-card-title>
        <span>{{ $t('t.LetterDematerialized') }}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty"
          fab
          x-small
          color="success"
          @click.stop="save()"
        >
          <v-icon ref="check">{{ $icon('i.Checked') }}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{ $icon('i.Undo') }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <grid
          item-min-width="310px"
          class="align-center"
        >
          <v-text-field
            v-model="settings.url"
            :label="$t('t.ApplicationBaseURL')"
            type="url"
          ></v-text-field>
          <v-text-field
            v-model="settings.token"
            :label="$t('t.Token')"
            :append-icon="showPrivateKey ? $icon('i.Eye') : $icon('i.EyeOff')"
            :type="showPrivateKey ? 'text' : 'password'"
            @click:append="showPrivateKey = !showPrivateKey"
          ></v-text-field>
          <v-text-field
            v-model="settings.privateKey"
            :label="$t('t.PrivateKey')"
            :append-icon="showToken ? $icon('i.Eye') : $icon('i.EyeOff')"
            :type="showToken ? 'text' : 'password'"
            @click:append="showToken = !showToken"
          >
          </v-text-field>
        </grid>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {
    Grid: () => import('@/components/grid')
  },
  mounted () {
    this.load()
  },
  activated () {
    this.load()
  },
  computed: {
    isDirty () {
      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings))
    },
    computedColumnBalanceId: {
      get () {
        return this.settings.colBalanceId
      },
      set (v) {
        this.settings.colBalanceId = v
      }
    }
  },
  data () {
    return {
      showPrivateKey: false,
      showToken: false,
      initialSettings: null,
      required: [
        v => !!v || this.$t('t.IsRequired')
      ],
      settings: null
    }
  },
  methods: {
    async load () {
      const r = await this.$http().get('/core/v6/settings/letter-dematerialized-settings')

      this.settings = r?.data
      this.initialSettings = Object.assign({}, r?.data)
    },
    async save () {
      await this.$http().post('/core/v6/settings/letter-dematerialized-settings', this.settings)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.load()
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    }
  }
}
</script>

<style lang="stylus">
.responsive-grid
  display grid
  grid-template-columns repeat(auto-fill, minmax(15rem, auto))
  column-gap 1em

.insurance-provider-title
  padding 1em
</style>
